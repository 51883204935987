import { defineInlineAdSlotSchema } from "scmp-app/components/schema-render/common/inline-ad-slot/schemas";
import { defineTrackViewedPercentageSchema } from "scmp-app/components/schema-render/common/track-viewed-percentage/schemas";

export const DefaultCustomContents = [
  defineTrackViewedPercentageSchema({
    percentage: "0",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "20",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "50",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "90",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "100",
  }),
  defineInlineAdSlotSchema({ row: 1, tag: "0" }),
  defineInlineAdSlotSchema({ row: 3, tag: "1" }),
  defineInlineAdSlotSchema({ row: 5, tag: "2" }),
  defineInlineAdSlotSchema({ row: 8, tag: "3" }),
  defineInlineAdSlotSchema({ row: 11, tag: "4" }),
  defineInlineAdSlotSchema({ row: 14, tag: "5" }),
  defineInlineAdSlotSchema({ infiniteAdsAfterNumber: "3", row: 17, tag: "6" }),
  defineInlineAdSlotSchema({ row: -1, tag: "7" }),
];

export const PrintCustomContents = [
  defineTrackViewedPercentageSchema({
    percentage: "0",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "20",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "50",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "100",
  }),
];
