import type { PartialPropsOf } from "react";
import { forwardRef, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { ContentCoverImage } from "scmp-app/components/content/content-cover-image";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { checkIsMainTopic } from "scmp-app/components/content/helpers";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { sanitizeReadonlyArray } from "scmp-app/lib/utils";
import type { magazinesStyleCardContentItemContent$key } from "scmp-app/queries/__generated__/magazinesStyleCardContentItemContent.graphql";

import { Container, Cover, CoverEntityLink, Headline, StyledEntityLink, Topic } from "./styles";

export type CoverImageAspectRatio = "1/1" | "3/2";
const COVER_IMAGE_VARIANTS: Record<
  CoverImageAspectRatio,
  PartialPropsOf<typeof ContentCoverImage>
> = {
  "1/1": {
    responsiveVariants: {
      desktopUp: "size1200x1200",
      mobileUp: "size500x500",
      tabletUp: "size500x500",
    },
  },
  "3/2": {
    responsiveVariants: {
      desktopUp: "size1200x800",
      mobileUp: "size540x360",
      tabletUp: "size540x360",
    },
  },
};

export type Props = {
  reference: magazinesStyleCardContentItemContent$key;
} & ContentItemRenderVariantProps;

const newContentItem = ({ ratio }: { ratio: CoverImageAspectRatio }) => {
  const coverImageVariants = COVER_IMAGE_VARIANTS[ratio];

  // React node
  const ContentItemComponent = forwardRef<HTMLDivElement, Props>(
    ({ className, reference }, elementReference) => {
      const article = useFragment(
        graphql`
          fragment magazinesStyleCardContentItemContent on Content {
            topics {
              entityId
              ...topicLinkTopic
            }
            ...entityLink
            ...helpersCheckIsMainTopicContent
            ...hooksContentItemProvidersContent
              @arguments(
                withCoverImage: true
                withCoverImageSize1200x1200: true
                withCoverImageSize500x500: true
                withCoverImageSize1200x800: true
                withCoverImageSize540x360: true
                withHeadline: true
              )
          }
        `,
        reference,
      );
      const providers = useContentItemProviders(article);

      const cover = (
        <CoverEntityLink reference={article}>
          {providers.coverImage(coverImageVariants)}
        </CoverEntityLink>
      );
      const mainTopic = useMemo(() => {
        const topics = sanitizeReadonlyArray(article.topics);
        return topics.find(t => checkIsMainTopic(article, t.entityId));
      }, [article]);

      const headline = (
        <StyledEntityLink reference={article}>
          {providers.headline({ preferSocialHeadline: true, variant: MagazinesStyleVariant })}
        </StyledEntityLink>
      );

      return (
        <Container className={className} ref={elementReference}>
          <Cover>{cover}</Cover>
          {mainTopic ? <Topic reference={mainTopic} /> : null}
          <Headline>{headline}</Headline>
        </Container>
      );
    },
  );

  ContentItemComponent.displayName = {
    "1/1": "ContentItemMagazinesStyleCardSquare",
    "3/2": "ContentItemMagazinesStyleCardWide",
  }[ratio];
  return ContentItemComponent;
};

export const ContentItemMagazinesStyleCardSquare = newContentItem({ ratio: "1/1" });
export const ContentItemMagazinesStyleCardWide = newContentItem({ ratio: "3/2" });
