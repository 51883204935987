import {
  HomepageThemeFindOurMoreDisplay,
  HomepageThemeSignUpButtonDisplay,
  NewsletterVariant,
  notEmpty,
} from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { massageToNewsletterItem } from "scmp-app/components/newsletter/helpers";
import { NewsletterWidgetContainer } from "scmp-app/components/newsletter/newsletter-widget-container";
import type { sectionNewsletterWidgetQuery$key } from "scmp-app/queries/__generated__/sectionNewsletterWidgetQuery.graphql";

export type Props = {
  className?: string;
  reference: sectionNewsletterWidgetQuery$key;
};

export const SectionNewsletterWidget: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const { section } = useFragment(
    graphql`
      fragment sectionNewsletterWidgetQuery on Query
      @argumentDefinitions(entityId: { type: "String!" }) {
        section(filter: { entityId: $entityId }) {
          ... on Section {
            relatedNewsletters {
              ...helpersMassageToNewsletterItemNewsletter
            }
          }
        }
      }
    `,
    reference_,
  );
  const newsletterItems =
    section.relatedNewsletters
      ?.map(newsletter => massageToNewsletterItem(newsletter))
      .filter(notEmpty) ?? [];

  return (
    <NewsletterWidgetContainer
      className={className}
      findOutMoreButtonDisplayComponent={<HomepageThemeFindOurMoreDisplay />}
      newsletterItems={newsletterItems}
      signUpButtonDisplayComponent={<HomepageThemeSignUpButtonDisplay />}
      variant={NewsletterVariant.Section}
    />
  );
};

SectionNewsletterWidget.displayName = "SectionNewsletterWidget";
