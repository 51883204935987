/**
 * @generated SignedSource<<c4238c46b1f34652e309c0a60e37c0fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionSeoSection$data = {
  readonly description: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly entityId: string;
  readonly entityUuid: string;
  readonly image: {
    readonly url: string;
  } | null | undefined;
  readonly name: string;
  readonly parentSection: string | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseDeepLinkTagsBase" | "hooksUseDynamicMetaTags">;
  readonly " $fragmentType": "sectionSeoSection";
};
export type sectionSeoSection$key = {
  readonly " $data"?: sectionSeoSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionSeoSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionSeoSection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityUuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "type": "OG"
          }
        }
      ],
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": "image(filter:{\"type\":\"OG\"})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentSection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseDeepLinkTagsBase"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseDynamicMetaTags"
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "2c2a69cf953d449516ee3645712d21dc";

export default node;
