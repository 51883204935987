import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { setFullViewportWidthStyles } from "scmp-app/lib/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

export const LeadStory = styled.div`
  grid-area: lead-story;

  margin-block-start: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 40px;
  }
`;

export const Header = styled.div`
  grid-area: header;
`;

export const Container = styled.div`
  ${setFullViewportWidthStyles}
`;

export const Wrapper = styled.div`
  display: grid;
  grid:
    "top-ads" min-content
    "header" min-content
    "lead-story" min-content
    "subsection-1" min-content
    "section-container" min-content
    "newsletter" min-content
    / minmax(0, 1280px);

  max-inline-size: 1280px;
  margin-inline: auto;
`;

export const SectionContainer = styled.div`
  grid-area: section-container;

  display: grid;
  grid:
    "subsection-2" min-content
    "second-ads" min-content
    "subsection-3" min-content
    "subsection-4" min-content
    "third-ads" min-content
    "subsection-5" min-content
    "recommended" min-content
    "subsection-6" min-content
    "subsection-7" min-content
    / minmax(0, 1280px);
  gap: 40px;

  margin-block-start: 40px;

  ${props => props.theme.breakpoints.up("desktop")} {
    gap: 48px;

    margin-block-start: 48px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    gap: 80px;

    margin-block-start: 40px;
  }
`;

export const Recommended = styled.div`
  grid-area: recommended;
`;

export const Newsletter = styled.div`
  grid-area: newsletter;
`;

const Subsection = styled.div``;

export const Subsection1 = styled(Subsection)`
  grid-area: subsection-1;

  margin-block-start: 40px;

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 48px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 40px;
  }
`;

export const Subsection2 = styled(Subsection)`
  grid-area: subsection-2;
`;

export const Subsection3 = styled(Subsection)`
  grid-area: subsection-3;
`;

export const Subsection4 = styled(Subsection)`
  grid-area: subsection-4;
`;

export const Subsection5 = styled(Subsection)`
  grid-area: subsection-5;
`;

export const SecondAds = styled.div`
  grid-area: second-ads;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline: 40px;
    padding-block-start: 48px;

    border-block-start: 1px solid #777777;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    padding-block-start: 80px;
  }
`;

export const ThirdAds = styled(SecondAds)`
  grid-area: third-ads;
`;

export const AdContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  overflow-x: hidden;
`;

type AdSlotProps = {
  $responsiveVariants: ResponsiveVariants<{
    $height: number;
    $padding: number;
  }>;
};
export const AdSlotContainer = styled.div<AdSlotProps>`
  grid-area: top-ads;
  ${setFullViewportWidthStyles()}
  box-sizing: content-box;

  background-color: #f6f6f6;

  overflow: hidden;
  ${props =>
    setResponsiveVariantStyles(
      props.$responsiveVariants,
      variant => css`
        display: block;

        block-size: ${`${variant.$height}px`};
        padding-block: ${`${variant.$padding}px`};
      `,
    )}
`;
