import type { FunctionComponent } from "react";

import { config } from "scmp-app/data";

import { Button, ButtonMessage, Container, HeadlineContainer, Italic, SubHeadline } from "./styles";

export const SectionStyleNewsletter: FunctionComponent = () => {
  const handleButtonClick = () => {
    window.location.href = `${config.general.siteUrl}/newsletters?defaultCheckedEntityIds=322992`;
  };

  return (
    <Container>
      <SubHeadline>Stay on trend</SubHeadline>
      <HeadlineContainer>
        W<Italic>i</Italic>th our w<Italic>ee</Italic>k<Italic>l</Italic>y n<Italic>e</Italic>ws
        <Italic>le</Italic>tt
        <Italic>e</Italic>r
      </HeadlineContainer>
      <Button onClick={handleButtonClick}>
        <ButtonMessage>Sign Up Now</ButtonMessage>
      </Button>
    </Container>
  );
};

SectionStyleNewsletter.displayName = "SectionStyleNewsletter";
