export type InlineAdSlotSchema = {
  attribs: InlineAdSlotSchemaAttributes;
  type: typeof InlineAdSlotSchemaType;
};

export const InlineAdSlotSchemaType = "inline-ad-slot";

export type InlineAdSlotSchemaAttributes = {
  infiniteAdsAfterNumber?: string;
  tag: string;
};

export function isInlineAdSlotSchema(node: SchemaNode): node is InlineAdSlotSchema {
  return node.type === InlineAdSlotSchemaType;
}

export type InlineAdSlotSchemaRow = {
  row: number;
} & InlineAdSlotSchema;

export function defineInlineAdSlotSchema(options: {
  infiniteAdsAfterNumber?: string;
  row: number;
  tag: string;
}): InlineAdSlotSchemaRow {
  const { infiniteAdsAfterNumber, row, tag } = options;
  return {
    attribs: {
      infiniteAdsAfterNumber,
      tag,
    },
    row,
    type: InlineAdSlotSchemaType,
  };
}
