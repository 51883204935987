import { page as NewslettersPage } from "scmp-app/pages/newsletters";

export const useNewslettersPageLink = () => {
  const { pathname } = NewslettersPage.route({});
  if (typeof location !== "undefined") {
    const url = new URL(location.href);
    url.pathname = pathname;
    url.searchParams.set("defaultCheckedEntityIds", "322743");
    const href = url.toString();
    return { href };
  }

  return { href: "" };
};
