/**
 * @generated SignedSource<<7baf3d5b1203c169e56811a0410215c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksUseSectionBaseAdvertisingInfoSection$data = {
  readonly advertZone: string | null | undefined;
  readonly entityId: string;
  readonly " $fragmentType": "hooksUseSectionBaseAdvertisingInfoSection";
};
export type hooksUseSectionBaseAdvertisingInfoSection$key = {
  readonly " $data"?: hooksUseSectionBaseAdvertisingInfoSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseSectionBaseAdvertisingInfoSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseSectionBaseAdvertisingInfoSection",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "version",
          "value": 2
        }
      ],
      "kind": "ScalarField",
      "name": "advertZone",
      "storageKey": "advertZone(version:2)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "39cfeb35b3aec7c24806ace90571b370";

export default node;
