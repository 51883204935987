/**
 * @generated SignedSource<<51c8ef8854db75c1448149058a30a89b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type headerPostMagazineQuery$data = {
  readonly postMagSection: {
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"entityOnelineMenuQueueItemsEdge">;
        }>;
      } | null | undefined;
    } | null | undefined;
    readonly urlAlias: string;
  };
  readonly " $fragmentType": "headerPostMagazineQuery";
};
export type headerPostMagazineQuery$key = {
  readonly " $data"?: headerPostMagazineQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerPostMagazineQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "headerPostMagazineQuery",
  "selections": [
    {
      "alias": "postMagSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "71"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "subSections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QueueItemsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "entityOnelineMenuQueueItemsEdge"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"71\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fd05336b433b248e39c5dbccadf863f9";

export default node;
