import { type FunctionComponent, startTransition, useMemo, useRef, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { section as sectionData } from "scmp-app/data/section";
import type { sectionDescriptionSection$key } from "scmp-app/queries/__generated__/sectionDescriptionSection.graphql";

import SvgIconArrow from "./icon-arrow.svg";
import {
  Container,
  ContentContainer,
  DescriptionContainer,
  ShowButton,
  StyledHTMLSchemaRender,
} from "./styles";

type Props = {
  className?: string;
  collapsedOnDesktop?: boolean;
  reference: sectionDescriptionSection$key;
  sectionId: string;
};

export const SectionDescription: FunctionComponent<Props> = ({
  className,
  collapsedOnDesktop = false,
  reference: reference_,
  sectionId,
}) => {
  const section = useFragment(
    graphql`
      fragment sectionDescriptionSection on Section {
        description {
          text
          json
        }
      }
    `,
    reference_,
  );

  const [isShowFullProfile, setIsShowFullProfile] = useState(false);
  const isHavingMoreContent = useMemo(() => {
    const textLength = section?.description?.text?.length ?? 0;

    return textLength > 100;
  }, [section?.description?.text?.length]);

  const containerReference = useRef<HTMLDivElement>(null);

  const enableDescription: string[] = [
    sectionData.chinaFutureTech.entityId,
    sectionData.us.entityId,
    sectionData.us.usElections.entityId,
    sectionData.chinaFutureTech.aerospace.entityId,
    sectionData.chinaFutureTech.ai.entityId,
    sectionData.chinaFutureTech.biomedicine.entityId,
    sectionData.chinaFutureTech.evs.entityId,
    sectionData.chinaFutureTech.robotics.entityId,
    sectionData.chinaFutureTech.semiconductors.entityId,
    sectionData.us.usElections.analysis.entityId,
    sectionData.us.usElections.explainers.entityId,
    sectionData.us.usElections.latest.entityId,
  ];

  if (!enableDescription.includes(sectionId) || !section.description?.text) {
    return null;
  }

  return (
    <Container className={className} ref={containerReference}>
      <DescriptionContainer
        $collapsedOnDesktop={collapsedOnDesktop}
        $isHavingMoreContent={isHavingMoreContent}
        $isShowFullProfile={isShowFullProfile}
      >
        <ContentContainer>
          <StyledHTMLSchemaRender schema={section.description?.json} />
        </ContentContainer>
        <ShowButton
          onClick={() => {
            setIsShowFullProfile(true);
            startTransition(() => {
              setTimeout(() => {
                const elementPosition =
                  containerReference.current?.getBoundingClientRect().top ?? 0;
                const scrollPosition = document?.scrollingElement?.scrollTop ?? 0;

                document.scrollingElement?.scrollTo({
                  behavior: "smooth",
                  top: scrollPosition + elementPosition - 45,
                });
              }, 150);
            });
          }}
        >
          <span>Show more</span>
          <SvgIconArrow />
        </ShowButton>
      </DescriptionContainer>
    </Container>
  );
};

SectionDescription.displayName = "SectionDescription";
