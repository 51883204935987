import type { StyledComponent } from "@emotion/styled";
import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

import { Br } from "scmp-app/components/schema-render/common/br";
import { Div } from "scmp-app/components/schema-render/common/div";
import { Heading } from "scmp-app/components/schema-render/common/heading";
import { Link } from "scmp-app/components/schema-render/common/link";
import { Paragraph } from "scmp-app/components/schema-render/common/paragraph";
import { Text } from "scmp-app/components/schema-render/common/text";
import type {
  ComponentMap,
  ContentSchemaRenderProps,
  DefaultRenderMapFunction,
  RenderComponent,
  RenderFunction,
} from "scmp-app/components/schema-render/content";
import {
  StyledCaption,
  StyledEm,
  StyledLi,
  StyledOl,
  StyledS,
  StyledSpan,
  StyledStrong,
  StyledSub,
  StyledSup,
  StyledUl,
  StyledUnderline,
} from "scmp-app/components/schema-render/content/styles";
import type { SupportedContentNode } from "scmp-app/components/schema-render/content/types";
import { normalizeJsxAttribute } from "scmp-app/lib/utils";

export type Props<
  ExtraRenderMapFunction = Partial<
    Record<SupportedContentNode, RenderFunction<Record<string, unknown>>>
  >,
> = {
  /**
   * This map will merge with the default component map
   */
  extraComponentMap?: ComponentMap<null | RenderComponent>;
  /**
   * This function map will merge with the default render function map
   * It can be useful when we need the relay data which is not inside the ContentSchemaRender
   */
  extraRenderFunctionMap?: ExtraRenderMapFunction;
  schema: Nullish<Schema>;
};

export const RenderContext = createContext<{ schema: Schema }>({
  schema: [],
});
export const useRenderContext = () => useContext(RenderContext);

export const HTMLSchemaRender: FunctionComponent<Props> = ({
  extraComponentMap = {},
  extraRenderFunctionMap = {},
  schema,
  ...attributes
}) => {
  const defaultComponentMap: ComponentMap = {
    a: Link,
    br: Br,
    caption: StyledCaption,
    div: Div,
    em: StyledEm,
    h1: Heading,
    h2: Heading,
    h3: Heading,
    h4: Heading,
    h5: Heading,
    h6: Heading,
    li: StyledLi,
    ol: StyledOl,
    p: Paragraph,
    s: StyledS,
    span: StyledSpan,
    strong: StyledStrong,
    sub: StyledSub,
    sup: StyledSup,
    text: Text,
    u: StyledUnderline,
    ul: StyledUl,
  };

  const componentMap = {
    ...defaultComponentMap,
    ...extraComponentMap,
  } as ComponentMap;
  const defaultRenderMapFunctions = Object.entries(componentMap).reduce(
    (accumulator, [type, Component_]) => {
      if (!Component_) {
        return accumulator;
      }
      type ContentSchemaPropsWithReference = ContentSchemaRenderProps;
      type RenderComponent<
        Props extends ContentSchemaPropsWithReference = ContentSchemaPropsWithReference,
        Component = StyledComponent<Props>,
      > = {
        isHandlingOwnChildren?: boolean;
      } & Component;
      const Component = Component_ as unknown as RenderComponent;

      accumulator[type as keyof DefaultRenderMapFunction] = (
        props,
        keyIndex,
        index,
        children,
        schemaNode,
        parentSchemaNode,
      ) => (
        <Component
          {...props}
          index={index}
          key={keyIndex}
          parentSchemaNode={parentSchemaNode}
          schemaNode={schemaNode}
        >
          {children}
        </Component>
      );
      return accumulator;
    },
    {} as DefaultRenderMapFunction,
  );

  const renderFunctions = { ...defaultRenderMapFunctions, ...extraRenderFunctionMap };

  const deepRender = (
    index: number,
    itemIndex: number,
    schemaNode: SchemaNode,
    parentSchemaNode?: SchemaNode,
  ): null | ReactNode => {
    const type = schemaNode.type as keyof DefaultRenderMapFunction;
    const Component = componentMap[type];
    const renderFunction = renderFunctions[type];

    if (!Component && !renderFunction) {
      return null;
    }
    const renderChildren = () =>
      schemaNode.children?.reduce((childrenComponent, child, index_) => {
        const childComp = deepRender(index, index_, child, schemaNode);
        childComp && childrenComponent.push(childComp);
        return childrenComponent;
      }, [] as ReactNode[]);

    return renderFunction(
      {
        ...normalizeJsxAttribute({ ...schemaNode.attribs, ...attributes }),
      },
      itemIndex,
      index,
      Component?.isHandlingOwnChildren ? [] : renderChildren(),
      schemaNode,
      parentSchemaNode,
    );
  };

  const contextValue = useMemo(
    () => ({
      schema: schema ?? [],
    }),
    [schema],
  );

  if (!schema) return null;
  return (
    <RenderContext.Provider value={contextValue}>
      {schema.map((s, index) => deepRender(index, index, s, undefined))}
    </RenderContext.Provider>
  );
};

HTMLSchemaRender.displayName = "HTMLSchemaRender";
