/**
 * @generated SignedSource<<f002c76d2b4706f105f198e2547b62e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionDescriptionSection$data = {
  readonly description: {
    readonly json: Schema | null | undefined;
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sectionDescriptionSection";
};
export type sectionDescriptionSection$key = {
  readonly " $data"?: sectionDescriptionSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionDescriptionSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionDescriptionSection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "json",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "a94626d80617ddc5c9d1ef52109470e3";

export default node;
