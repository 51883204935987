import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import {
  type Targeting,
  TargetingPaidType,
} from "scmp-app/components/advertisement/ad-slots/types";
import type { hooksUseSectionBaseAdvertisingInfoSection$key } from "scmp-app/queries/__generated__/hooksUseSectionBaseAdvertisingInfoSection.graphql";

export const useSectionBaseAdvertisingInfo = (
  section_: hooksUseSectionBaseAdvertisingInfoSection$key,
) => {
  const section = useFragment(
    graphql`
      fragment hooksUseSectionBaseAdvertisingInfoSection on Section {
        advertZone(version: 2)
        entityId
      }
    `,
    section_,
  );

  const sectionBaseAdvertisingInfo = useMemo<{
    targeting: Targeting;
    zone: string;
  }>(
    () => ({
      targeting: {
        paid: TargetingPaidType.Free,
        scsid: [section.entityId],
      },
      zone: section.advertZone ?? "default",
    }),
    [section],
  );

  return sectionBaseAdvertisingInfo;
};
