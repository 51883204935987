export type TrackViewedPercentageSchema = {
  attribs: TrackViewedPercentageSchemaAttributes;
  type: typeof TrackViewedPercentageSchemaType;
};

export const TrackViewedPercentageSchemaType = "track-viewed-percentage";

export type TrackViewedPercentageSchemaAttributes = {
  percentage: string;
};

export function isTrackViewedPercentageSchema(
  node: SchemaNode,
): node is TrackViewedPercentageSchema {
  return node.type === TrackViewedPercentageSchemaType;
}

export function defineTrackViewedPercentageSchema(options: {
  percentage: string;
}): TrackViewedPercentageSchema {
  const { percentage } = options;
  return {
    attribs: {
      percentage,
    },
    type: "track-viewed-percentage",
  };
}
