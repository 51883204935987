import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { postMagazineNewsletter$key } from "scmp-app/queries/__generated__/postMagazineNewsletter.graphql";

import { useNewslettersPageLink } from "./hooks";
import {
  Container,
  CultureText,
  Description,
  DescriptionWrapper,
  DiscoveryText,
  Divider,
  Heading,
  LiveText,
  RotateText,
  ShortDescription,
  SignUpButton,
  SubheadingWrapper,
  TermAndConditionContainer,
  TermAndConditionLink,
  TitleWrapper,
  Weekday,
} from "./styles";

type Props = {
  reference: postMagazineNewsletter$key;
};

export const PostMagazineNewsletter: FunctionComponent<Props> = ({ reference }) => {
  const newslettersPageLink = useNewslettersPageLink();
  const data = useFragment(
    graphql`
      fragment postMagazineNewsletter on Query {
        newsletter: newsletter(filter: { entityId: "322743" }) {
          description {
            text
          }
          shortDescription {
            text
          }
          summary {
            text
          }
        }
      }
    `,
    reference,
  );

  const description = data.newsletter?.description?.text ?? "";
  const shortDescription = data.newsletter?.shortDescription?.text ?? "";
  const summary = data.newsletter?.summary?.text ?? "";

  return (
    <Container>
      <Weekday>
        <RotateText>updates</RotateText>
        <RotateText>{summary}</RotateText>
      </Weekday>
      <TitleWrapper>
        <Heading>PostMag Weekly Newsletter</Heading>
        <SubheadingWrapper>
          <LiveText>life.&nbsp;</LiveText>
          <CultureText>culture.&nbsp;</CultureText>
          <DiscoveryText>discovery.</DiscoveryText>
        </SubheadingWrapper>
        <ShortDescription>{shortDescription}</ShortDescription>
      </TitleWrapper>
      <Divider />
      <DescriptionWrapper>
        <Description>{description}</Description>
        <SignUpButton {...newslettersPageLink}>sign up now</SignUpButton>
        <TermAndConditionContainer>
          By registering, you agree to our{" "}
          <TermAndConditionLink href="/terms-conditions">T&C</TermAndConditionLink> and{" "}
          <TermAndConditionLink href="/privacy-policy">Privacy Policy</TermAndConditionLink>
        </TermAndConditionContainer>
      </DescriptionWrapper>
    </Container>
  );
};

PostMagazineNewsletter.displayName = "PostMagazineNewsletter";
