import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { graphql, useFragment } from "react-relay";

import type { Targeting } from "scmp-app/components/advertisement/ad-slots/types";
import type { contextsSectionContextProviderSection$key } from "scmp-app/queries/__generated__/contextsSectionContextProviderSection.graphql";

import { useSectionBaseAdvertisingInfo } from "./hooks";

type ContextValue = {
  advertisement: {
    targeting: Targeting;
    zone: string;
  };
};

const SectionContext = createContext<ContextValue>({
  advertisement: {
    targeting: {},
    zone: "default",
  },
});

type Props = {
  children: ReactNode;
  reference: contextsSectionContextProviderSection$key;
};
export const SectionContextProvider: React.FunctionComponent<Props> = ({ children, reference }) => {
  const section = useFragment(
    graphql`
      fragment contextsSectionContextProviderSection on Section {
        ...hooksUseSectionBaseAdvertisingInfoSection
      }
    `,
    reference,
  );

  const sectionBaseAdvertisingInfo = useSectionBaseAdvertisingInfo(section);

  const sectionContext = {
    advertisement: sectionBaseAdvertisingInfo,
  };

  return <SectionContext.Provider value={sectionContext}>{children}</SectionContext.Provider>;
};
SectionContextProvider.displayName = "SectionContextProvider";

export const useSectionContext = () => useContext(SectionContext);
