import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontPlayfairDisplay } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

import Background from "./background.png";

export const Container = styled.div`
  ${setFullViewportWidthStyles()}

  display: flex;
  flex-direction: column;
  justify-content: center;

  block-size: 600px;

  background-color: #111111;
  background-image: url(${Background.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: 560px;
  }

  ${props => props.theme.breakpoints.up("largeDesktop")} {
    background-size: contain;
  }
`;

export const SubHeadline = styled.div`
  margin-block-end: 12px;

  color: #ffffff;
  font-size: 16px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
`;

export const HeadlineContainer = styled.div`
  margin-block-end: 28px;

  color: #ffffff;
  font-size: 28px;
  font-family: ${fontPlayfairDisplay};
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  align-self: center;

  block-size: 44px;
  padding-block: 12px;
  padding-inline: 24px;

  text-align: center;
  text-transform: uppercase;

  border-radius: 3px;

  background-color: #ffffff;

  cursor: pointer;
`;

export const ButtonMessage = styled.span`
  font-weight: 600;
  font-size: 16px;
  font-family: ${fontBarlowSemiCondensed};
  letter-spacing: 0.1em;
`;
